var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.info
    ? _c(
        "tr",
        [
          _c(
            "td",
            { key: "participant_" + _vm.info.id + "_" + _vm.info.status },
            [
              _c("img", {
                staticClass: "icon mr-1",
                attrs: { src: "/img/message_type/message.png" },
              }),
              _c(
                "span",
                [_c("MessageStatus", { attrs: { status: _vm.info.status } })],
                1
              ),
              _c("ContactInformation", {
                attrs: {
                  "no-icon": "",
                  user_uuid: _vm.user_uuid,
                  functionbox_uuid: _vm.functionbox_uuid,
                  identifier: _vm.info.email,
                },
              }),
            ],
            1
          ),
          _c(
            "td",
            [
              _c(
                "b-dropdown",
                {
                  attrs: {
                    "toggle-tag": "button",
                    "toggle-class": "no-border",
                    "no-caret": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "button-content",
                        fn: function () {
                          return [_c("i", { staticClass: "fal fa-ellipsis-h" })]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2991361956
                  ),
                },
                [
                  _vm.info.status != "VIEWED" && _vm.info.status != "RECALLED"
                    ? _c(
                        "b-dropdown-item",
                        {
                          staticClass: "btn-fill mt-2",
                          attrs: { size: "sm" },
                          on: { click: _vm.resend },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("RESEND_NOTIFICATION")) + "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "b-dropdown-item",
                    {
                      on: {
                        click: function ($event) {
                          _vm.show = true
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("MORE_INFORMATION")))]
                  ),
                  _c("b-dropdown-divider", { staticClass: "mt-2" }),
                  _vm.info.status == "NOT_VIEWED" ||
                  _vm.info.status == "SENT_WITHOUT_NOTIFICATION"
                    ? _c(
                        "b-dropdown-item",
                        {
                          staticClass: "btn-fill mt-2",
                          attrs: { variant: "danger", size: "sm" },
                          on: { click: _vm.recall },
                        },
                        [_vm._v(_vm._s(_vm.$t("RECALL")))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-sidebar",
            {
              attrs: {
                id: "message-participant",
                right: "",
                "no-slide": "",
                shadow: "",
                backdrop: "",
              },
              model: {
                value: _vm.show,
                callback: function ($$v) {
                  _vm.show = $$v
                },
                expression: "show",
              },
            },
            [
              _c(
                "div",
                { staticClass: "px-3 py-2" },
                [
                  _c(
                    "b-overlay",
                    { attrs: { show: _vm.loading, rounded: "sm" } },
                    [
                      _c("ContactInformation", {
                        attrs: {
                          user_uuid: _vm.user_uuid,
                          functionbox_uuid: _vm.functionbox_uuid,
                          identifier: _vm.info.email,
                        },
                      }),
                      _c("hr", { staticClass: "pb-0 mb-0 mt-2" }),
                      _c("dl", { staticClass: "clearfix mt-2" }, [
                        _c("dt", [_vm._v(_vm._s(_vm.$t("STATUS")))]),
                        _c(
                          "dd",
                          [
                            _c("MessageStatus", {
                              attrs: {
                                status: _vm.info.status,
                                "show-text": "",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.info.status == "VIEWED"
                          ? _c("dt", [_vm._v(_vm._s(_vm.$t("VIEWED")))])
                          : _vm._e(),
                        _vm.info.status == "VIEWED"
                          ? _c("dd", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.FormatDateTime(_vm.info.fetched_date)
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                        _vm.info.status == "RECALLED"
                          ? _c("dt", [_vm._v(_vm._s(_vm.$t("RECALLED")))])
                          : _vm._e(),
                        _vm.info.status == "RECALLED"
                          ? _c("dd", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.FormatDateTime(_vm.info.recalled_date)
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c("hr", { staticClass: "pb-0 mb-0 mt-2" }),
                      _vm.count != 0
                        ? _c(
                            "b-button",
                            {
                              staticClass: "btn-fill mt-2",
                              attrs: {
                                variant: "primary",
                                size: "sm",
                                to: {
                                  name: "Conversation",
                                  query: {
                                    conversation_uuid:
                                      this.item.conversation_uuid,
                                    user_uuid: this.user_uuid,
                                    functionbox_uuid: this.functionbox_uuid,
                                    message_uuid: this.message_uuid,
                                  },
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("SHOW_CONVERSATION")))]
                          )
                        : _vm._e(),
                      _vm.info.status == "NOT_VIEWED" ||
                      _vm.info.status == "SENT_WITHOUT_NOTIFICATION"
                        ? _c(
                            "b-button",
                            {
                              staticClass: "btn-fill mt-2",
                              attrs: { variant: "danger", size: "sm" },
                              on: { click: _vm.recall },
                            },
                            [_vm._v(_vm._s(_vm.$t("RECALL")))]
                          )
                        : _vm._e(),
                      _vm.info.status != "VIEWED" &&
                      _vm.info.status != "RECALLED"
                        ? _c(
                            "b-button",
                            {
                              staticClass: "btn-fill mt-2",
                              attrs: { variant: "primary", size: "sm" },
                              on: { click: _vm.resend },
                            },
                            [_vm._v(_vm._s(_vm.$t("RESEND_NOTIFICATION")))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }