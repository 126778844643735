<template>
    <tr v-if="info">      
      <td :key="'participant_' + info.id + '_' + info.status">
        <img class="icon mr-1" src="/img/message_type/message.png" /> 
        <span>
          <MessageStatus :status="info.status"></MessageStatus>
        </span>
        <ContactInformation
          no-icon
          :user_uuid="user_uuid"
          :functionbox_uuid="functionbox_uuid"
          :identifier="info.email"
        ></ContactInformation>
      </td>
      <td>
        <b-dropdown toggle-tag="button" toggle-class="no-border" no-caret>
          <template #button-content><i class="fal fa-ellipsis-h"></i></template>
          <b-dropdown-item
            v-if="info.status != 'VIEWED' && info.status != 'RECALLED'"
            size="sm"
            class="btn-fill mt-2"
            @click="resend"
            >{{ $t("RESEND_NOTIFICATION") }}
          </b-dropdown-item>
          <b-dropdown-item @click="show = true">{{
            $t("MORE_INFORMATION")
          }}</b-dropdown-item>
          <b-dropdown-divider class="mt-2"></b-dropdown-divider>
          <b-dropdown-item        
            v-if="info.status == 'NOT_VIEWED' || info.status == 'SENT_WITHOUT_NOTIFICATION'"
            variant="danger"
            size="sm"
            class="btn-fill mt-2"
            @click="recall"
            >{{ $t("RECALL") }}</b-dropdown-item
          >
        </b-dropdown>
      </td>

      <b-sidebar
        id="message-participant"
        v-model="show"
        right
        no-slide
        shadow
        backdrop
      >
        <div class="px-3 py-2">
          <b-overlay :show="loading" rounded="sm">
            <ContactInformation
              :user_uuid="user_uuid"
              :functionbox_uuid="functionbox_uuid"
              :identifier="info.email"
            ></ContactInformation>

            <hr class="pb-0 mb-0 mt-2" />

            <dl class="clearfix mt-2">
              <dt>{{ $t("STATUS") }}</dt>
              <dd>
                <MessageStatus :status="info.status" show-text></MessageStatus>
              </dd>
              <dt v-if="info.status == 'VIEWED'">{{ $t("VIEWED") }}</dt>
              <dd v-if="info.status == 'VIEWED'">
                {{ FormatDateTime(info.fetched_date) }}
              </dd>
              <dt v-if="info.status == 'RECALLED'">{{ $t("RECALLED") }}</dt>
              <dd v-if="info.status == 'RECALLED'">
                {{ FormatDateTime(info.recalled_date) }}
              </dd>
            </dl>

            <hr class="pb-0 mb-0 mt-2" />

            <b-button
              v-if="count != 0"
              variant="primary"
              size="sm"
              class="btn-fill mt-2"
              :to="{
                name: 'Conversation',
                query: {
                  conversation_uuid: this.item.conversation_uuid,
                  user_uuid: this.user_uuid,
                  functionbox_uuid: this.functionbox_uuid,
                  message_uuid: this.message_uuid,
                },
              }"
              >{{ $t("SHOW_CONVERSATION") }}</b-button
            >

            <b-button
              v-if="info.status == 'NOT_VIEWED' || info.status == 'SENT_WITHOUT_NOTIFICATION'"
              variant="danger"
              size="sm"
              class="btn-fill mt-2"
              @click="recall"
              >{{ $t("RECALL") }}</b-button
            >
            <b-button
              v-if="info.status != 'VIEWED' && info.status != 'RECALLED'"
              variant="primary"
              size="sm"
              class="btn-fill mt-2"
              @click="resend"
              >{{ $t("RESEND_NOTIFICATION") }}</b-button
            >
          </b-overlay>
        </div>
      </b-sidebar>
    </tr>
</template>
<script>
import MessageStatus from "../MessageStatus";
import ContactInformation from "../ContactInformation";
export default {
components: { MessageStatus, ContactInformation },
props: [
  "message_uuid",
  "index",
  "item",
  "islast",
  "creator",
  "status",
  "user_uuid",
  "functionbox_uuid",
],
data() {
  return {
    show: false,
    info: this.item,
    contactInfo: null,
    loading: false,
    showDialog: false,
    viewed: false,
    viewed_date: "",
    count: 0,
  };
},
methods: {
  onShow: function () {
    this.$root.$emit("bv::hide::popover");
  },
  onClose: function () {
    this.showDialog = false;
  },
  recall: function () {
    let self = this;
    this.$bvModal
      .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
        size: "sm",
        buttonSize: "sm",
        okVariant: "primary",
        okTitle: this.$t("YES"),
        cancelTitle: this.$t("NO"),
        bodyClass: "messagebox",
        footerClass: "p-2  text-center messagebox",
        hideHeaderClose: false,
        centered: true,
      })
      .then(function (value) {
        if (value) {
          self.loading = true;
          self.$http
            .post(self.user.hostname + "/message/user/recall", {
              participant_id: self.info.id,
            })
            .then(function (response) {
              self.loading = false;
              self.info.recalled = 1;
              self.info.status = "RECALLED";
              self.info.recalled_date = response.data.recalled_date;
              self.$noty.info(self.$t("SAVED"));
            })
            .catch(function () {
              self.loading = false;
            });
        }
      })
      .catch(function () {});
  },
  resend: function () {
    let self = this;
    this.$bvModal
      .msgBoxConfirm(this.$t("ARE_YOU_SURE"), {
        size: "sm",
        buttonSize: "sm",
        okVariant: "primary",
        okTitle: this.$t("YES"),
        cancelTitle: this.$t("NO"),
        bodyClass: "messagebox",
        footerClass: "p-2  text-center messagebox",
        hideHeaderClose: false,
        centered: true,
      })
      .then(function (value) {
        if (value) {
          self.loading = true;
          self.$http
            .post(self.user.hostname + "/message/resend", {
              participant_id: self.info.id,
            })
            .then(function () {
              self.loading = false;
              if (self.info.sent != undefined) {
                self.info.sent = 1;
              }
              if(self.info.status == "SENT_WITHOUT_NOTIFICATION")
              {
                self.info.status = "NOT_VIEWED";
              }
              self.$noty.info(self.$t("SENT"));
            })
            .catch(function () {
              self.loading = false;
            });
        }
      })
      .catch(function () {});
  },
},
computed: {
  popoverTarget() {
    return "Participant" + this.index;
  },
  showStatus() {
    if (this.info.status == 'RECALLED') {
      return this.$t("RECALLED");
    }
    if (this.info.status == 'RESPONDED') {
      return this.$t("RESPONDED");
    }
    if (this.info.status == 'VIEWED') {
      return this.$t("VIEWED");
    }
    if(this.info.status == 'SENT_WITHOUT_NOTIFICATION') {
      return this.$t("NOT_SENT");
    }
    if(this.info.status == "NOT_VIEWED")
    {
      return this.$t("NOT_VIEWED");
    }
    return "";
  },
},
mounted() {},
};
</script>
<style></style>
