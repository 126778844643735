var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.message
    ? _c("section", [
        !_vm.isCreator
          ? _c("hr", { staticClass: "pb-0 mb-0 mb-2 mt-2" })
          : _vm._e(),
        !_vm.isCreator && _vm.recipientsNoCreator.length != 0
          ? _c(
              "span",
              _vm._l(_vm.recipientsNoCreator, function (item, index) {
                return _c(
                  "span",
                  { key: index + "-recipient" },
                  [
                    _c("Recipient", {
                      attrs: {
                        index: index,
                        functionbox_uuid: _vm.functionbox_uuid,
                        isMeeting: _vm.IsMeeting(_vm.message.message_type),
                        user_uuid: _vm.user_uuid,
                        item: item,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
        _vm.isCreator && !_vm.isConversation
          ? _c("table", { staticClass: "table table-sm table-menu" }, [
              _vm.participantsNoCreator.length != 0
                ? _c(
                    "span",
                    _vm._l(_vm.participantsNoCreator, function (item, index) {
                      return _c("Participant", {
                        key: index + "-participant",
                        attrs: {
                          message_uuid: _vm.message.message_uuid,
                          index: index,
                          item: item,
                          creator: _vm.isCreator,
                          user_uuid: _vm.user_uuid,
                          functionbox_uuid: _vm.functionbox_uuid,
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm.externalFilter.length != 0
                ? _c(
                    "span",
                    _vm._l(_vm.externalFilter, function (item, index) {
                      return _c("SecureLink", {
                        key: index + "-external",
                        attrs: {
                          message: _vm.message,
                          response: _vm.message.settings.require_response,
                          index: index,
                          item: item,
                          creator: _vm.isCreator,
                          user_uuid: _vm.user_uuid,
                          functionbox_uuid: _vm.functionbox_uuid,
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm.hasExternalSystemParticipants
                ? _c(
                    "span",
                    _vm._l(
                      _vm.external_system_participants,
                      function (item, index) {
                        return _c("ExternalParticipant", {
                          key: index + "-external_system_participant",
                          staticClass: "ms-fontSize-12",
                          attrs: {
                            message_uuid: _vm.message.message_uuid,
                            index: index,
                            item: item,
                            creator: _vm.isCreator,
                            user_uuid: _vm.user_uuid,
                            functionbox_uuid: _vm.functionbox_uuid,
                          },
                        })
                      }
                    ),
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }